<template>
    <div class="form__group">
        <input
            class="form__field"
            :class="{error: error}"
            :value="value"
            :type="type"
            :placeholder="placeholder"
            @input="$emit('eventInput', $event.target.value)"
        >

        <transition name="fade">
            <div v-if="error" class="form__group__error">
                {{ errorText }}
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'Field',
    props: {
        placeholder: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'text'
        },
        value: {
            type: String,
            default: ''
        },
        error: {
            type: Boolean,
            default: false
        },
        errorText: {
            type: String,
            default: ''
        }
    }
}
</script>

<style lang="scss">

    .form {

        &__group {
            margin: 0 0 14px 0;

            &__error {
                font-size: 15px;
                color: $red;
                margin: 4.5px 0 -5.5px 0;
                padding: 0 9.5px;
                font-size: 13px;
                line-height: 1.2;
            }
        }

        &__field {
            display: block;
            height: 50px;
            width: 100%;
            padding: 15px 20px;
            background-color: rgba(255, 255, 255, 0.3);
            border: 1px solid rgba(255, 255, 255, 0.3);
            border-radius: 12px;
            font-size: 16px;
            color: #fff;
            box-shadow: none;
            transition: .3s ease-in-out;

            &::placeholder {
                color: #ffff;
                transition: 75ms ease-in-out;
            }

            &:focus::placeholder {
                opacity: 0;
            }

            &.error {
                border-color: $red;
                color: $red;

                &::placeholder {
                    color: $red;
                }
            }
        }
    }
</style>