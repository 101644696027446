<template>
  <button
    @click.prevent="$emit('eventClick')"
    :type="type"
    class="button"
    :disabled="disabled"
    :class="classNames"
  >
    <span>{{ text }}</span>
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    text: String,
    classNames: String,
    disabled: [String, Boolean],
    type: {
      type: String,
      default: 'button'
    }
  },
  methods: {
    eventclick() {
      this.$emit('eventclick');
    }
  }
};
</script>

<style lang="scss">
  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background:
      radial-gradient(43.33% 144.17% at 87.5% -33.33%, rgba(255, 57, 57, 0.48) 4.27%, rgba(198, 250, 133, 0.0816) 100%) no-repeat 0 0 / 100% auto,
      linear-gradient(203.05deg, #FEF40F -37.6%, #FC8E29 135.35%) no-repeat 0 0 / 100% auto;
    box-shadow: 0px 10px 20px rgba(84, 22, 116, 0.3);
    border-radius: 50px;
    color: $purple;
    border: none;
    font-weight: bold;
    font-size: 18px;
    transition: .3s ease-in-out;
    text-decoration: none;
    cursor: pointer;

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    @media (max-width: 1023px) {
      height: 50px;
    }

    @media (min-width: 1024px) {
      height: 60px;

      &:not(:disabled) {

        &:hover {
          background-position: right 0;
          background-size: 150% auto;
          box-shadow: 0px 10px 20px rgba(84, 22, 116, 0.3);
        }
      }
    }

    img {
      margin-left: 12px;
    }
  }
</style>