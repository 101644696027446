<template>
    <section class="our-girls" id="girls">

        <Preloader class="our-girls-preloader" v-if="!girls"/>

        <div v-else class="our-girls__list container">

            <swiper
                :options="swiperOption"
                ref="swiperSlider"
            >
                <swiper-slide
                    v-for="(item, key) in girls"
                    :key="key"
                    class="our-girls__item"
                >
                    <div class="our-girls__item__wrapper" @click="$modal.show('join-modal')">
                        <img class="our-girls__item__image" :src="applyImgSrc(item.photo)" :alt="item.name">
                        <div class="our-girls__item__info">
                            <div class="our-girls__item__title">{{ item.name }}</div>
                            <div class="our-girls__item__age">{{ item.age }} years <span>Online</span></div>
                            <img :src="require('@/assets/cam_slide.svg')" alt="#">
                        </div>
                    </div>
                </swiper-slide>
            </swiper>

            <div class="our-girls__nav">

                <div class="our-girls__prev our-girls__btn">
                    <img :src="require('@/assets/arrow-prev.svg')" alt="Prev">
                </div>

                <div class="swiper-pagination our-girls__pagi" slot="pagination"></div>

                <div class="our-girls__next our-girls__btn">
                    <img :src="require('@/assets/arrow-next.svg')" alt="Next">
                </div>

            </div>

        </div>

    </section>
</template>

<script>

import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
import 'swiper/swiper.scss';

import Preloader from '@/components/Base/Preloader.vue';

export default {
    name: 'OurGirls',
    components: {
        Swiper,
        SwiperSlide,
        Preloader
    },
    directives: {
        swiper: directive
    },
    data: () => ({
        girls: null,
        swiperOption: {
            slidesPerView: 5,
            slidesPerGroup: 1,
            slidesPerColumn: 2,
            slideToScroll: 1,
            autoplay: {
                delay: 3000,
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },
            navigation: {
                nextEl: '.our-girls__next',
                prevEl: '.our-girls__prev'
            },
            breakpoints: {
                1440: {
                    spaceBetween: 30,
                    slidesPerView: 5,
                },
                1024: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                320: {
                    slidesPerColumn: 1,
                    slidesPerView: 'auto',
                    spaceBetween: 10,
                }
            }
        },
    }),
    created() {
        this.getGirls();
    },
    methods: {
        chunkArray(array, chunk) {
            const newArray = [];
            for (let i = 0; i < array.length; i += chunk) {
                newArray.push(array.slice(i, i + chunk));
            }
            return newArray;
        },
        async getGirls() {
            try {
                const query = await fetch('/api/?action=get_profiles', {
                    method: 'POST',
                });
                this.girls = await query.json();
            } catch(error) {
                console.error(error);
            }
        },
        applyImgSrc(url) {
			return '//storage.victoriyaclub.com' + url;
		},
    }
}
</script>

<style lang="scss">

    .our-girls {
        position: relative;

        .our-girls-preloader {
            position: relative;
            min-height: 30vh;
        }

        &__list {
            position: relative;

            @include xs {
                max-width: unset;
                padding-right: 0;
            }

            &::before {
                content: '';
                z-index: -1;
                position: absolute;
                top: 0;
                left: 50%;
                background: #B544EC;
                opacity: 0.15;
                filter: blur(400px);
                transform: translateX(-50%);

                @include xs {
                    width: 600px;
                    height: 600px;
                }

                @include sm {
                    width: 1016px;
                    height: 1016px;
                    max-height: 80vh;
                }

                @include md {
                    width: 1342px;
                    height: 1342px;
                    max-height: 80vh;
                }

                @include lg {
                    top: 120px;
                    width: 1489px;
                    height: 1489px;
                    max-height: 80vh;
                }
            }
        }

        &__item {
            flex-shrink: 0;
            border: 10px solid rgba(255, 255, 255, 0.9);
            filter: drop-shadow(0px 8px 12px rgba(70, 43, 100, 0.3));
            border-radius: 30px;
            transition: opacity .3s ease-in-out;

            &:hover {
                opacity: 0.9;
                cursor: pointer;
            }

            @include xs {
                width: 209px;
                height: 282px;
            }

            @media (min-width: 768px) {
                filter: drop-shadow(0px 8px 10px rgba(70, 43, 100, 0.3));
            }

            @include sm {
                width: 209px;
                height: 282px;
                margin-top: 0 !important;
                margin-bottom: 20px !important;
            }

            @include md {
                width: 275px;
                height: 370px;
                margin-top: 0 !important;
                margin-bottom: 20px !important;
            }

            @media (min-width: 1440px) and (max-width: 1919px) {
                width: 273px;
                height: 369px;
                margin-top: 0 !important;
                margin-bottom: 30px !important;
            }

            @media (min-width: 1920px) {
                width: 300px;
                height: 405px;
                margin-top: 0 !important;
                margin-bottom: 30px !important;
            }

            &__wrapper {
                position: relative;
                height: 100%;
                border-radius: 20px;
                overflow: hidden;

                > img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }

            &__info {
                z-index: 2;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                background: radial-gradient(159.49% 605.84% at 42.86% 100%, rgba(161, 61, 217, 0.4) 0%, rgba(46, 198, 232, 0.4) 100%);
                backdrop-filter: blur(10px);
                color: #fff;
                padding: 7px 20px 12px 20px;

                span {

                    @include xs {
                        display: none;
                    }

                    @include sm {
                        display: none;
                    }

                    @media (min-width: 1024px) {
                        display: flex;
                        align-items: center;
                        font-weight: 500;
                        color: #0FC86F;

                        &::before {
                            content: '';
                            display: block;
                            flex-shrink: 0;
                            width: 6px;
                            height: 6px;
                            margin: 0 5px 0 8px;
                            border-radius: 100%;
                            background-color: #0FC86F;
                        }
                    }
                }

                img {
                    position: absolute;
                    flex-shrink: 0;
                    right: 20px;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 26px;
                    height: 26px;
                }
            }

            &__title {
                width: 78%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;

                @include xs {
                    margin: 0 0 4px 0;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 25px;
                }

                @include sm {
                    margin: 0 0 4px 0;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 25px;
                }

                @include md {
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 30px;
                }

                @include lg {
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 30px;
                }
            }

            &__age {

                @include xs {
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 20px;
                }

                @include sm {
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 20px;
                }

                @include md {
                    display: flex;
                    align-items: center;
                    font-size: 16px;
                    line-height: 20px;
                    color: rgba(255, 255, 255, 0.8);
                }

                @include lg {
                    display: flex;
                    align-items: center;
                    font-size: 16px;
                    line-height: 20px;
                    color: rgba(255, 255, 255, 0.8);
                }
            }
        }

        &__nav {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0;
            line-height: 0;

            .our-girls__btn {

                &[aria-disabled="false"] {
                    cursor: pointer;
                }
            }

            @include xs {
                display: none;
            }

            @include sm {
                margin-bottom: 60px;
            }

            @include md {
                margin-bottom: 70px;
            }

            @include lg {
                margin-bottom: 100px;
            }

            @media (min-width: 768px) {
                height: 42px;
            }
        }

        &__pagi {

            .swiper-pagination-bullet {
                cursor: pointer;
            }

            @media (min-width: 768px) {
                display: flex;
                margin: 0 22px;

                > span {
                    flex-shrink: 0;
                    width: 8px;
                    height: 8px;
                    margin: 0 4px;
                    background: #fff;
                    transition: .3s ease-in-out;
                    border: 2px solid transparent;
                    border-radius: 100%;

                    &.swiper-pagination-bullet-active {
                        background-color: $purple;
                        border-color: $purple;
                    }
                }
            }
        }
    }

    .swiper-container {

        @include xs {
            margin-left: -20px;
            padding: 32px 0 26px 20px;
        }

        @include sm {
            margin: 0 -18px;
            padding: 40px 18px;
        }

        @include md {
            margin: 0 -18px;
            padding: 40px 18px;
        }

        @include lg {
            margin: 0 -28px;
            padding: 40px 28px;
        }
    }

    @media (min-width: 768px) {
        .swiper-container-multirow-column > .swiper-wrapper {
            flex-direction: row;
        }
    }
</style>