<template>
    <footer class="footer">
        <picture class="footer__background">
            <source media="(min-width:1920px)" :srcset="require('@/assets/f-1920.png')">
            <source media="(min-width:1440px)" :srcset="require('@/assets/f-1440.png')">
            <source media="(min-width:1024px)" :srcset="require('@/assets/f-1024.png')">
            <source media="(min-width:768px)" :srcset="require('@/assets/f-768.png')">
            <source media="(min-width:320px)" :srcset="require('@/assets/f-320.png')">
            <img :src="require('@/assets/f-320.png')" alt="#">
        </picture>
        <div class="footer__wrapper container">
            <div class="footer__info">
                <img class="footer__logo" :src="require('@/assets/f-logo.svg')" alt="#" />
                <div class="footer__copyright">
                    Finegirls.com 2008-{{ (new Date()).getFullYear() }} © All rights reserved
                </div>
            </div>
            <div class="footer__payments">
                <img
                    v-for="(payment, index) in payments"
                    :key="index"
                    :src="payment"
                    alt="#"
                >
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'Footer',
    data: () => ({
        payments: [
            require('@/assets/p-1.png'),
            require('@/assets/p-2.png'),
            require('@/assets/p-3.png'),
            require('@/assets/p-4.png')
        ]
    })
}
</script>

<style lang="scss">
    .footer {
        position: relative;
        z-index: 1;

        @include xs {
            padding: 34px 0 33px 0;
        }

        @include sm {
            padding: 37.5px 0;
        }

        @include md {
            padding: 37.5px 0;
        }

        @include lg {
            padding: 40.5px 0;
        }

        @media (min-width: 1920px) {
            padding: 57.5px 140px;
        }

        &__background {
            z-index: -1;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            font-size: 0;

            img {
                font-size: 0;
                width: 100%;
                height: 100%;
                max-width: unset;
                object-fit: cover;
                object-position: center;
            }
        }

        &__wrapper {
            display: flex;
            align-items: center;

            @include xs {
                flex-direction: column;
            }
        }

        &__info {
            display: flex;
            align-items: center;
            @include xs {
                margin-bottom: 30px;
            }

            @media (min-width: 768px) {
                margin-right: auto;
                align-items: flex-end;
            }
        }

        &__logo {
            height: auto;
            margin-right: 30px;

            @include xs {
                width: 109px;
            }
            @include sm {
                display: none;
            }
            @include md {
                width: 113px;
            }
            @include lg {
                width: 130px;
            }
        }

        &__copyright {
            font-size: 14px;
            line-height: 140%;
            color: #FFF;
        }

        &__payments {
            display: flex;
            margin: 0 -7.5px;

            img {
                max-width: 90px;
                width: calc(25% - 15px);
                margin: 0 7.5px;
            }
        }
    }
</style>