import Vue from 'vue';
import App from './App.vue';
import router from './router';
import VModal from 'vue-js-modal';

Vue.config.productionTip = false;

Vue.use(VModal);

new Vue({
	data: {
    isPreloadGeneral: false
  },
	router,
	render: h => h(App)
}).$mount('#app');
