<template>
    <header class="header">
        <picture class="header__background">
            <source media="(min-width:1920px)" :srcset="require('@/assets/bg-1920.png')">
            <source media="(min-width:1440px)" :srcset="require('@/assets/bg-1440.png')">
            <source media="(min-width:1024px)" :srcset="require('@/assets/bg-1024.png')">
            <source media="(min-width:768px)" :srcset="require('@/assets/bg-768.png')">
            <source media="(min-width:320px)" :srcset="require('@/assets/bg-320.png')">
            <img :src="require('@/assets/bg-1440.png')" alt="Easy Dating">
        </picture>
        <picture class="header__girl">
            <source media="(min-width:1920px)" :srcset="require('@/assets/g-1920.png')">
            <source media="(min-width:1440px)" :srcset="require('@/assets/g-1440.png')">
            <source media="(min-width:1024px)" :srcset="require('@/assets/g-1024.png')">
            <source media="(min-width:768px)" :srcset="require('@/assets/g-768.png')">
            <source media="(min-width:320px)" :srcset="require('@/assets/g-320.png')">
            <img :src="require('@/assets/g-1440.png')" alt="#">
        </picture>
        <div class="container">
            <div class="header__top">
                <img class="header__logotype" :src="require('@/assets/logo.svg')" alt="#" />
                <div class="header__top__pagi">
                    <a href="#girls">Our girls</a>
                    <img :src="require('@/assets/dot.svg')" alt="#" />
                    <a href="#gift">Gifts</a>
                </div>
                <div class="sign-up" @click="$modal.show('join-modal')">
                    Sign up
                </div>
            </div>
            <div class="header__content">
                <h1 class="title title--h1">Online<br>Dating Site</h1>
                <div class="header__text">Do not waste any minute and join a popular dating website to meet the girl who knows how to make you happy.</div>

                <Button
                    class="button"
                    text="JOIN US"
                    @eventClick="$modal.show('join-modal')"
                />
            </div>
        </div>
    </header>
</template>

<script>
import Button from '@/components/Base/Button';

export default {
    name: 'Header',
    components: {
        Button,
    }
}
</script>

<style lang="scss">
    .header {
        position: relative;

        .container {
            position: relative;
            z-index: 3;
        }

        &__background {
            z-index: 1;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            img {
                width: 100%;
                max-width: unset;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }

        &__girl {
            z-index: 2;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;

            @include sm {
                height: 568px;
            }

            @include md {
                height: 568px;
            }

            @media (min-width: 1440px) and (max-width: 1919px) {
                height: 568px;
            }

            @media (min-width: 1920px) {
                height: 758px;
            }

            img {
                width: 100%;
                max-width: unset;
                height: 100%;
                object-fit: contain;
                object-position: right center;

                @include xs {
                    object-fit: contain;
                    object-position: center 100px;
                }
            }
        }

        &__top {
            display: flex;
            align-items: center;

            @include xs {
                padding: 30px 0 210px 0;
            }

            @include sm {
                padding: 40px 0 122px 0;
            }

            @include md {
                padding: 40px 0 107px 0;
            }

            @media (min-width: 1440px) and (max-width: 1919px) {
                padding: 40px 0 102px 0;
            }

            @media (min-width: 1920px) {
                padding: 40px 0 193px 0;
            }

            &__pagi {

                @include xs {
                    display: none;
                }

                @media (min-width: 768px) {
                    display: flex;
                    align-items: center;
                    margin: 0 36px 0 0;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 120%;

                    a {
                        color: rgba(255, 255, 255, 0.8);
                        text-decoration: none;

                        &:hover,
                        &:active,
                        &:focus {
                            opacity: 0.8;
                        }
                    }

                    img {
                        width: 4px;
                        height: 4px;
                        margin: 0 15px;
                    }
                }
            }
        }

        &__content {
            color: #FFF;

            @include xs {
                padding: 0 0 47px 0;
            }

            @include sm {
                padding: 0 0 150px 0;
                width: 380px;
            }

            @include md {
                padding: 0 0 115px 0;
            }

            @media (min-width: 1440px) and (max-width: 1919px) {
                padding: 0 0 104px 0;
            }

            @media (min-width: 1920px) {
                padding: 0 0 192px 0;
            }
        }

        &__logotype {
            height: auto;

            @include xs {
                width: 113px;
                margin: 0 auto;
            }

            @media (min-width: 768px) {
                margin: 0 auto 0 0;
            }

            @include sm {
                width: 113px;
            }

            @include md {
                width: 113px;
            }

            @include lg {
                width: 130px;
            }
        }

        &__text {
            font-size: 20px;
            letter-spacing: 0.02em;

            @include xs {
                margin: 0 0 20px 0;
            }

            @media (min-width: 768px) {
                margin: 0 0 35px 0;
            }

            @include md {
                width: 450px;
            }

            @include lg {
                width: 570px;
            }
        }

        .button {

            @include xs {
                width: 100%;
            }

            @include sm {
                width: 235px;
            }

            @media (min-width: 1024px) {
                width: 270px;
            }
        }
    }

    .sign-up {

        @include xs {
            display: none;
        }

        @media (min-width: 768px) {
            flex-shrink: 0;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            height: 42px;
            padding: 10px 20px;
            background: rgba(255, 255, 255, 0.1);
            border: 1px solid rgba(255, 255, 255, 0.3);
            box-sizing: border-box;
            border-radius: 50px;
            font-weight: 500;
            font-size: 18px;
            line-height: 120%;
            color: #FFF;
            transition: .3s ease-in-out;
            cursor: pointer;

            &:hover {
                background: rgba(255, 255, 255, 0.2);
            }
        }
    }
</style>