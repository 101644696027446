<template>
	<div id="app">
		<router-view />
		<Preloader class="preloader-general" :showHide="$root.isPreloadGeneral"/>
	</div>
</template>

<script>
import Preloader from '@/components/Base/Preloader.vue';

export default {
	name: "App",
	components: {
		Preloader
	}
}
</script>

<style lang="scss">
	@import '@/scss/reset';
	@import '@/scss/typography';

	.preloader-general {
		position: fixed;
		z-index: 999999;
		top: 0;
		left: 0;
		right: auto;
		bottom: auto;
		height: 100%;
		width: 100%;
		background-image: -webkit-linear-gradient(left, #0674fd, #00d0f9);
	}

	html {
		font-size: 16px;
		font-family: 'Quicksand', sans-serif;
		color: $black;
    scroll-behavior: smooth;
	}

	#app {
		overflow-x: hidden; /* WTF */
	}

	body {
		width: 100%;
		line-height: 1.5;
		background-color: #fff;
	}

	.container {
		width: 100%;
		padding: 0 20px;

		@include xs {
			max-width: 375px;
			margin: 0 auto;
		}

		@include sm {
			padding: 0 50px;
			max-width: 768px;
			margin: 0 auto;
		}

		@include md {
			padding: 0 80px;
			max-width: 1024px;
			margin: 0 auto;
		}

		@media (min-width: 1440px) and (max-width: 1919px) {
			padding: 0 130px;
			max-width: 1440px;
			margin: 0 auto;
		}

		@media (min-width: 1920px) {
			padding: 0 150px;
			max-width: 1920px;
			margin: 0 auto;
		}
	}

	.vm--overlay {
		background: rgba(125, 48, 161, .8) !important; /* WTF */
	}
</style>