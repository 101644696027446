<template>
    <div class="info-section">
        <div class="info container" id="gift">
            <img class="info__icon" :src="require('@/assets/gift.svg')" alt="#">
            <picture class="info__background">
                <source media="(min-width:1920px)" :srcset="require('@/assets/b-1920.png')">
                <source media="(min-width:1440px)" :srcset="require('@/assets/b-1440.png')">
                <source media="(min-width:1024px)" :srcset="require('@/assets/b-1024.png')">
                <source media="(min-width:768px)" :srcset="require('@/assets/b-768.png')">
                <source media="(min-width:320px)" :srcset="require('@/assets/b-320.png')">
                <img :src="require('@/assets/b-320.png')" alt="#">
            </picture>
            <div class="info__text">
                <div class="info__title">Get 10<br> Gift Credits</div>
                <div class="info__caption">upon Registration!</div>
                <Button
                    class="button"
                    text="GET A GIFT"
                    @eventClick="$modal.show('join-modal')"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Button from '@/components/Base/Button';

export default {
    name: 'Info',
    components: {
        Button
    },
    data: () => ({

    }),
    methods: {
    }
}
</script>

<style lang="scss">

    .info-section {

        @include xs {
            padding: 51px 0 50px 0;
        }

        @include sm {
            padding: 70px 0 80px 0;
        }

        @include md {
            padding: 70px 0 80px 0;
        }

        @media (min-width: 1440px) and (max-width: 1919px) {
            padding: 90px 0 100px 0;
        }

        @media (min-width: 1920px) {
            padding: 100px 0 110px 0;
        }
    }

    .info {
        position: relative;
        filter: drop-shadow(0px 8px 40px rgba(70, 43, 100, 0.3));
        margin: auto;

        @include xs {
            // margin: 51px auto 50px auto;
        }

        @include sm {
            // margin: 70px auto 80px auto;
        }

        @include md {
            // margin: 70px auto 80px auto;
        }

        @media (min-width: 1440px) and (max-width: 1919px) {
            // margin: 90px auto 100px auto;
        }

        @media (min-width: 1920px) {
            // margin: 100px auto 110px auto;
        }

        &__icon {
            z-index: 2;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, -50%);

            @include xs {
                width: 40px;
                height: 40px;
            }

            @include sm {
                width: 50px;
                height: 50px;
            }

            @include md {
                width: 58px;
                height: 58px;
            }

            @media (min-width: 1440px) and (max-width: 1919px) {
                width: 80px;
                height: 80px;
            }

            @media (min-width: 1920px) {
                width: 109px;
                height: 109px;
            }
        }

        &__background {
            position: absolute;
            top: 0;
            left: 20px;
            width: calc(100% - 40px);
            height: 100%;
            border-radius: 30px;
            overflow: hidden;
            pointer-events: none;

            @include sm {
                width: calc(100% - 100px);
                left: 50px;
            }

            @include md {
                width: calc(100% - 160px);
                left: 80px;
            }

            @media (min-width: 1440px) and (max-width: 1919px) {
                width: calc(100% - 260px);
                left: 130px;
            }

            @media (min-width: 1920px) {
                width: calc(100% - 300px);
                left: 150px;
            }

            &::before {
                content: '';
                position: absolute;
                top: -1px;
                left: -1px;
                width: calc(100% - 18px);
                height: calc(100% - 18px);
                border-radius: 30px;
                border: 10px solid rgba(255, 255, 255, .9);
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: left top;
            }
        }

        &__text {
            z-index: 2;
            position: relative;
            display: flex;
            flex-direction: column;
            text-align: center;
            margin: 0 auto;

            @include xs {
                width: 192px;
                padding: 60px 0 43px 0;
            }

            @include sm {
                padding: 65px 0 40px 0;
            }

            @include md {
                padding: 75px 0 44px 0;
            }

            @media (min-width: 1440px) and (max-width: 1919px) {
               padding: 105px 0 68px 0;
            }

            @media (min-width: 1920px) {
                padding: 164px 0 106px 0;
            }

            .button {
                margin: 0 auto;
                @include xs {
                    width: 192px;
                }

                @include sm {
                    width: 270px;
                }

                @include md {
                    width: 270px;
                }

                @include lg {
                    width: 270px;
                }
            }
        }

        &__title {
            font-family: 'Gilroy', sans-serif;
            font-weight: 800;
            line-height: 100%;
            color: #fff;

            @include xs {
                margin-bottom: 4px;
                font-size: 30px;
            }

            @media (min-width: 768px) {
                br {
                    display: none;
                }
            }

            @include sm {
                margin-bottom: 4px;
                font-size: 46px;
            }

            @include md {
                margin-bottom: 4px;
                font-size: 46px;
            }

            @media (min-width: 1440px) and (max-width: 1919px) {
               margin-bottom: 10px;
               font-size: 60px;
            }

            @media (min-width: 1920px) {
                margin-bottom: 10px;
                font-size: 80px;
            }
        }

        &__caption {
            color: #fff;

            @include xs {
                margin-bottom: 23px;
                font-size: 16px;
                line-height: 20px;
            }

            @include sm {
                margin-bottom: 16px;
                font-size: 16px;
                line-height: 20px;
            }

            @include md {
                margin-bottom: 26px;
                font-size: 20px;
                line-height: 20px;
            }

            @media (min-width: 1440px) and (max-width: 1919px) {
                margin-bottom: 36px;
                font-size: 28px;
                line-height: 130%;
                letter-spacing: 0.02em;
            }

            @media (min-width: 1920px) {
                margin-bottom: 40px;
                font-size: 34px;
                line-height: 130%;
                letter-spacing: 0.02em;
            }
        }
    }
</style>