<template>
    <label class="form__checkbox" :class="{error: error}">
        <input
            :checked="value"
            type="checkbox"
            @change="onChange"
        >
        <pre></pre>
        {{ placeholder }}
    </label>
</template>

<script>
export default {
    name: 'Checkbox',
    props: {
        placeholder: {
            type: String,
            default: ''
        },
        value: {
            type: Boolean,
            default: false
        },
        error: {
            type: Boolean,
            default: false
        }
    },
    methods: {
      onChange (e) {
        this.$emit('update:value', e.target.checked)
      }
    }
}
</script>

<style lang="scss">
    .form__checkbox {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #F1DBFD;
        margin: 2px 0 30px 0;

        input {
            display: none;

            &:checked {

                ~ pre::before {
                    opacity: 1;
                }
            }
        }

        pre {
            position: relative;
            flex-shrink: 0;
            width: 20px;
            height: 20px;
            margin: 0 8px 0 0;
            background-color: rgba(255, 255, 255, 0.3);
            border: 1px solid rgba(255, 255, 255, 0.3);
            box-sizing: border-box;
            border-radius: 5px;
            transition: .3s ease-in-out;
            cursor: pointer;

            &::before {
                content: '';
                position: absolute;
                top: 6px;
                left: calc(50% - 5px);
                width: 12px;
                height: 7px;
                background: url('../../assets/check.svg') center no-repeat;
                background-size: contain;
                transition: 75ms ease-in-out;
                opacity: 0;
            }
        }

        &.error {

            pre {
                border-color: $red;
            }
        }
    }
</style>